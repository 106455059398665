@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --china-rose: #9a5665ff;
  --palatinate: #67264eff;
  --delft-blue: #36356eff;
  --rosy-brown: #cd9fa2ff;
  --russian-violet: #110b3bff;
}

html,
body,
#root,
.app {
  height: 100%;
  margin: 0;
  background-color: var(--russian-violet);
  color: var(--rosy-brown);
  @apply: var(--rosy-brown);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
